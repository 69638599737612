import {
  createInlineForm,
  createPopup,
  observeVisibility,
} from "@formcrafts/embed";

const hostname = new URL(import.meta.env.PUBLIC_APP_URL).hostname;
const debug = typeof window !== "undefined" && window.location &&
  window.location.search.includes("debug=true");

export const getValuesFrom = (element: Element) => {
  let values: Record<string, string | string[]> = {};
  try {
    const params = new URLSearchParams(window.location.search);
    for (const key of params.keys()) {
      if (key.toLowerCase().startsWith("field")) {
        values[key.toLowerCase().replace("[]", "")] = params.getAll(key);
      }
    }
  } catch (error) {
  }
  // Function to safely access deep properties
  const getNestedValue = (path, obj) => {
    return path.reduce((acc, part) => acc && acc[part], obj);
  };

  const attributes = Array.from(element.attributes).filter((attr) =>
    attr.name.startsWith("data-fc-prefill")
  );

  attributes.forEach((attr) => {
    const fieldName = attr.name.replace("data-fc-prefill-", "");
    let fieldValue = attr.value;
    if (fieldValue.startsWith("window.")) {
      const path = fieldValue.split(".").slice(1);
      const nestedValue = getNestedValue(path, window);
      if (nestedValue !== undefined) {
        fieldValue = nestedValue;
      } else {
        fieldValue = "";
      }
    }
    values[fieldName] = fieldValue;
  });
  return values;
};

const initializeLibrary = () => {
  // Fix (legacy x2)
  let legacy_buttons = document.querySelectorAll('[data-toggle="fcmodal"]');
  if (legacy_buttons) {
    for (let old of legacy_buttons) {
      if (old.getAttribute("data-target")) {
        old.setAttribute(
          "data-fc-open",
          old.getAttribute("data-target").replace("#", ""),
        );
        old.removeAttribute("data-target");
        old.removeAttribute("data-toggle");
      }
    }
  }
  // Load forms (legacy)
  const old_forms = (window as any)._fo || [];
  for (const form of old_forms) {
    const div_id = form.c;
    const form_key = form.i;
    const form_width = form.w ? parseFloat(form.w) : null;
    const form_align = form.d ?? "left";
    const form_mobile = form.m ?? 0;
    if (form_mobile) {
      const form_trigger = document.querySelector(`[data-fc-open="${div_id}"]`);
      if (!form_trigger) {
        if (debug) {
          console.error(
            `Formcrafts embed: element with data-fc-open="${div_id}" not found`,
          );
        }
        continue;
      }
      const href = form_trigger.getAttribute("href");
      try {
        new URL(href);
      } catch (error) {
        if (debug) {
          console.error(`Formcrafts embed: invalid URL ${href}`);
        }
        continue;
      }
      const href_url = new URL(href);
      // append ?popup to the URL at the beginning
      href_url.search = `?popup=true${href_url.search.replace("?", "&")}`;
      const new_url = href_url.toString().replace(
        "formcrafts.com/a",
        new URL(import.meta.env.PUBLIC_APP_URL).host,
      );
      if (debug) {
        console.log(
          `Formcrafts embed: ${
            form_trigger.getAttribute("href")
          } to ${new_url}`,
        );
      }
      form_trigger.setAttribute(
        "href",
        new_url,
      );
      continue;
    }
    const div = document.getElementById(div_id);
    if (!div) {
      if (debug) {
        console.error(`Formcrafts embed: element with id ${div_id} not found`);
      }
      continue;
    }
    if (form_width) {
      div.style.width = "100%";
      div.style.maxWidth = `${form_width}px`;
    }
    if (form_align === "center") {
      div.style.margin = "0 auto";
    } else if (form_align === "right") {
      div.style.margin = "0 0 0 auto";
    }
    createInlineForm({
      form: form_key,
      formBase: import.meta.env.PUBLIC_APP_URL,
      target: div as HTMLElement,
      width: form_width,
      seamless: false,
      redirectWithin: false,
    });
  }
  // Look for popup forms
  document.querySelectorAll('a[href*="?popup"]').forEach((element) => {
    const href = element.getAttribute("href");
    if (href) {
      const domainValid = new URL(href).hostname.endsWith(hostname);
      if (domainValid) {
        const formUrl = new URL(href);
        const key = formUrl.pathname.replace("/", "");
        const params = new URLSearchParams(formUrl.search);
        params.delete("popup");
        let widthString = element.getAttribute("data-fc-width") || "520";
        const width = widthString === null ? null : parseFloat(widthString);
        const redirectWithin =
          element.getAttribute("data-fc-redirect-within") === "true";

        if (key) {
          const popup = createPopup({
            form: key,
            formBase: import.meta.env.PUBLIC_APP_URL,
            width,
            redirectWithin,
            _params: params,
            values: getValuesFrom(element),
          });
          element.addEventListener("click", (e) => {
            e.preventDefault();
            popup.load();
            popup.open();
          });
          observeVisibility(element, () => {
            popup.load();
          });
        }
      }
    }
  });

  // Look for elements with data-fc-key attribute and insert forms
  document.querySelectorAll("[data-fc-key]").forEach((element) => {
    const key = element.getAttribute("data-fc-key");
    const seamless = element.getAttribute("data-fc-seamless") === "true";
    const redirectWithin =
      element.getAttribute("data-fc-redirect-within") === "true";
    const widthString = (element as HTMLElement).style.maxWidth || null;
    const width = widthString === null ? null : parseFloat(widthString);
    if (key) {
      createInlineForm({
        form: key,
        formBase: import.meta.env.PUBLIC_APP_URL,
        target: element as HTMLElement,
        width,
        seamless,
        redirectWithin,
        values: getValuesFrom(element),
      });
    }
  });
};

if (typeof document !== "undefined") {
  if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", initializeLibrary);
  } else {
    requestAnimationFrame(initializeLibrary);
  }
}
